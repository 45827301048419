import Chart from 'react-apexcharts';

type Props = {
    labels: any;
    data: any;
}

function VolumnChat(props: Props) {
    const { labels, data } = props;

    const options = {
        xaxis: {
            categories: labels
        },
        title: {
            text: "Transactions Volume"
        },
        dataLabels: {
            enabled: false
        },
    };

    const series = [
        {
            name: 'BUSD',
            data: data
        }
    ];

    return (
        <Chart options={options} series={series} type="area" height={400} />
    );
};

export default VolumnChat;