import actions from "../actions/action";

const initialState = {
    BUSD: []
};

type Action = {
    type: string;
    payload: any;
};

const reportReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case actions.TIME_SERIES_REPORT:
            return { ...state, ...action.payload };

        default: return state;
    }
};

export default reportReducer;