import React from 'react';

import logo from "../assets/img/mona-loading-default.gif";
import HelmetTitle from './HelmetTitle';

function OfflineStatus() {
    return (
        <div className="offline">
            <HelmetTitle title="Offline" />
            <img src={logo} alt="" width="100" />
            <h1 className="pb-5">You're offline right now. Check your connection.</h1>
        </div>
    )
}

export default OfflineStatus;