import actions from "./action";
import baseuri from "../../utils/baseuri";
import { retrieveToken } from "../../utils/utility";

export const getDeposite = (walletAddress: string, currencySymbol: string, startDateTimeStamp: any, endDateTimeStamp: any, merchantId: string, page: number, pageSize: number, setFetching: Function) => {
    return async (dispatch: Function) => {
        try {
            setFetching(true);
            const res = await baseuri.get(`/api/v1/transactionsReport/deposits?walletAddress=${walletAddress}&currencySymbol=${currencySymbol}&startDateTimeStamp=${startDateTimeStamp}&endDateTimeStamp=${endDateTimeStamp}&merchantId=${merchantId}&page=${page}&pageSize=${pageSize}`, {
                headers: {
                    'Authorization': `Bearer ${retrieveToken().accessToken}`
                }
            })
            dispatch({
                type: actions.DEPOSITS,
                payload: res.data.data
            })
            setFetching(false);
        } catch (e) {
            console.error(e)
            setFetching(false);
        }
    }
}

export const getWithdrawals = (destinationAddress: string, currencySymbol: string, transactionStatus: string, startDateTimeStamp: any, endDateTimeStamp: any, merchantId: string, page: number, pageSize: number, setFetching: Function) => {
    return async (dispatch: Function) => {
        try {
            setFetching(true);
            const res = await baseuri.get(`/api/v1/transactionsReport/withdrawals?destinationAddress=${destinationAddress}&currencySymbol=${currencySymbol}&transactionStatus=${transactionStatus}&startDateTimeStamp=${startDateTimeStamp}&endDateTimeStamp=${endDateTimeStamp}&merchantId=${merchantId}&page=${page}&pageSize=${pageSize}`, {
                headers: {
                    'Authorization': `Bearer ${retrieveToken().accessToken}`
                }
            })
            dispatch({
                type: actions.WITHDRAWALS,
                payload: res.data.data
            })
            setFetching(false);
        } catch (e) {
            console.error(e)
            setFetching(false);
        }
    }
}