import { useEffect, useState } from "react";
import Skeleton from 'react-loading-skeleton';
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import Layout from "./Layout";
import Spinner from "./Spinner";
import HelmetTitle from "./HelmetTitle";
import GasTransactionsTable from "./Tables/GasTransactionsTable";
import { getActiveMerchants } from "../redux/actions/merchantAction";
import { getGasTransactions } from "../redux/actions/remittanceAction";
import LargeCard from "./Cards/LargeCard";

function GasTransactions() {
    const dispatch = useDispatch();

    const [managedWalletAddress, setManagedWalletAddress] = useState("");
    const [transactionStatus, setTransactionStatus] = useState("");
    const [currencySymbol, setCurrencySymbol] = useState("");
    const [merchantId, setMerchantId] = useState("");
    const [startDateTimeStamp, setStartDateTimeStamp] = useState(null);
    const [endDateTimeStamp, setEndDateTimeStamp] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(false);

    const statues = [
        { name: "NOT_REFLECTED" },
        { name: "FAILED" },
        { name: "CONFIRMED" },
        { name: "PENDING" },
        { name: "NOT_PROPAGATED" }
    ];

    const currencies = [
        { symbol: "BTC" },
        { symbol: "LTC" },
        { symbol: "BNB" },
        { symbol: "USDT" },
        { symbol: "DAI" },
        { symbol: "BUSD" },
        { symbol: "XEND" }
    ];

    const { merchants } = useSelector((store: any) => store.merchant);
    const { records } = useSelector((store: any) => store.remittance);

    useEffect(() => {
        dispatch(getActiveMerchants(setLoading));
        dispatch(getGasTransactions(managedWalletAddress, transactionStatus, currencySymbol, moment(startDateTimeStamp).valueOf() || "", moment(endDateTimeStamp).valueOf() || "", merchantId, 1, 50, setFetching));

        /* eslint-disable-next-line */
    }, [dispatch]);

    const handleSearch = () => {
        // if (!(managedWalletAddress && transactionStatus && currencySymbol && startDateTimeStamp && endDateTimeStamp && merchantId)) {
        //     return toast.error("Select all input below")
        // }

        dispatch(getGasTransactions(managedWalletAddress, transactionStatus, currencySymbol, moment(startDateTimeStamp).valueOf() || "", moment(endDateTimeStamp).valueOf() || "", merchantId, 1, 50, setFetching));
    }

    const copyToClipBoard = async (copyMe: string) => {
        try {
            await navigator.clipboard.writeText(copyMe);
            toast.success('Copied to Clipboard')
        } catch (err) {
            toast.error('Failed to copy!');
        }
    };

    const columns = [
        {
            Header: 'Business Name',
            accessor: 'businessName',
        },
        {
            Header: 'Transaction Currency',
            accessor: 'currency',
        },
        {
            Header: 'Transaction TimeStamp',
            accessor: 'transactionTimeStamp',
            Cell: (cell: any) => moment(cell.value).format("lll")

        },
        {
            Header: 'Tnx Network Fee',
            accessor: 'networkTransactionFee'
        },
        {
            Header: 'Transaction Status',
            accessor: "transactionStatus",
        },
        {
            Header: 'Transaction Hash',
            accessor: "transactionHash",
            Cell: (cell: any) => {
                return (
                    <div className="d-flex align-items-center">
                        {cell.value && cell.value.length > 15 ? cell.value.substring(0, 20) + "****" : cell.value}
                        <span className="material-icons-outlined ml-3 cursor-pointer" onClick={() => copyToClipBoard(cell.row.original.linkToTransaction)}>copy_all</span>
                    </div>
                )
            }
        },
        {
            Header: 'Wallet Address',
            accessor: "walletAddress",
            Cell: (cell: any) => {
                return (
                    <div className="d-flex align-items-center">
                        {cell.value && cell.value.length > 15 ? cell.value.substring(0, 20) + "****" : cell.value}
                        <span className="material-icons-outlined ml-3 cursor-pointer" onClick={() => copyToClipBoard(cell.row.original.linkToAddress)}>copy_all</span>
                    </div>
                )
            }
        }
    ];

    return (
        <>
            <HelmetTitle title="Gas Transactions" />
            <Layout>
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="row filter-row shadow-none">
                            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 pb-3 pb-md-0">
                                {loading
                                    ? <Skeleton height={45} />
                                    : <input className="input-select" placeholder="Enter Wallet Address" onChange={(e) => setManagedWalletAddress(e.target.value)} />
                                }
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 pb-3 pb-md-0">
                                {loading
                                    ? <Skeleton height={45} />
                                    : <select className="input-select" defaultValue={'DEFAULT'} onChange={(e) => setTransactionStatus(e.target.value)}>
                                        <option value="DEFAULT" disabled>Select status</option>
                                        {statues.map((status: any, i: number) => (
                                            <option key={i} value={status.name}>{status.name}</option>
                                        ))}
                                    </select>
                                }
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 pb-3 pb-md-0">
                                {loading
                                    ? <Skeleton height={45} />
                                    : <select className="input-select" defaultValue={'DEFAULT'} onChange={(e) => setCurrencySymbol(e.target.value)}>
                                        <option value="DEFAULT" disabled>Select currency</option>
                                        {currencies.map((currency: any, i: number) => (
                                            <option key={i} value={currency.symbol}>{currency.symbol}</option>
                                        ))}
                                    </select>
                                }
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 pb-3 pb-md-0">
                                {loading
                                    ? <Skeleton height={45} />
                                    : <select className="input-select" defaultValue={'DEFAULT'} onChange={(e) => setMerchantId(e.target.value)}>
                                        <option value="DEFAULT" disabled>Select merchant</option>
                                        {merchants.map((merchant: any, i: number) => <option key={i} value={merchant.id}>{merchant.businessName}</option>)}
                                    </select>
                                }
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 pb-3 pb-md-0">
                                <DatePicker
                                    className="input-select"
                                    placeholderText="Start Date"
                                    selected={startDateTimeStamp}
                                    onChange={(date: any) => setStartDateTimeStamp(date)}
                                />
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 pb-3 pb-md-0">
                                <DatePicker
                                    className="input-select"
                                    placeholderText="End Date"
                                    selected={endDateTimeStamp}
                                    onChange={(date: any) => setEndDateTimeStamp(date)}
                                />
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 ml-auto pt-3 ">
                                <button type="button" className="btn btn-success btn-search rounded-pill" disabled={fetching ? true : false} onClick={handleSearch}>
                                    <i className="fas fa-filter mr-2" />Filter
                                </button>
                            </div>
                        </div>
                        {records.length === 0
                            ? <LargeCard />
                            : fetching
                                ? <Spinner text="Fetching data..." />
                                : <GasTransactionsTable
                                    columns={columns}
                                    data={records}
                                    managedWalletAddress={managedWalletAddress}
                                    transactionStatus={transactionStatus}
                                    currencySymbol={currencySymbol}
                                    startDateTimeStamp={startDateTimeStamp}
                                    endDateTimeStamp={endDateTimeStamp}
                                    merchantId={merchantId}
                                    setFetching={setFetching}
                                />
                        }
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default GasTransactions;