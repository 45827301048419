import { FormEvent, useState } from "react";

import HelmetTitle from "./HelmetTitle";
import Loading from "./Loading";
import { login } from "../redux/actions/authAction";
import Footer from "./Footer";

function Login() {
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    const data = { emailAddress, password };

    login(data, setLoading);
  };

  return (
    <>
      <HelmetTitle title="Login" />
      {loading
        ? <Loading text="Logging in..." />
        : <div className="account-page">
          <div className="main-wrapper">
            <div className="account-content">
              <div className="container">
                <div className="account-box">
                  <div className="account-wrapper">
                    <h2 className="pb-4 text-center mb-0">Welcome</h2>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="email"
                          placeholder="Email address"
                          required
                          value={emailAddress}
                          onChange={(e) => setEmailAddress(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="password"
                          placeholder="Password"
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div className="form-group text-center">
                        <button className="btn btn-primary account-btn rounded-pill" type="submit">
                          Login
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <Footer />
    </>
  );
};

export default Login;