import { FC } from "react";

const Footer: FC = () => {
    return (
        <footer className="fixed-bottom py-3">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <p className="mb-0">© Switch Wallet Dashboard</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;