import { useEffect, useState } from "react";
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Layout from "./Layout";
import Spinner from "./Spinner";
import HelmetTitle from "./HelmetTitle";
import RemittanceWalletsTable from "./Tables/RemittanceWalletsTable";
import { getActiveMerchants } from "../redux/actions/merchantAction";
import { getRemittanceWallets } from "../redux/actions/walletAction";
import LargeCard from "./Cards/LargeCard";

function RemittanceWallets() {
    const dispatch = useDispatch();

    const [merchantId, setMerchantId] = useState("");
    const [fetching, setFetching] = useState(false);
    const [loading, setLoading] = useState(false);

    const { merchants } = useSelector((store: any) => store.merchant);
    const { walletsCases } = useSelector((store: any) => store.wallet);

    useEffect(() => {
        dispatch(getActiveMerchants(setLoading));
        dispatch(getRemittanceWallets(merchantId, setFetching));

        /* eslint-disable-next-line */
    }, [dispatch]);

    const handleSearch = () => {
        // if (!(merchantId)) return toast.error("Select the input below");

        dispatch(getRemittanceWallets(merchantId, setFetching));
    }

    const copyToClipBoard = async (copyMe: string) => {
        try {
            await navigator.clipboard.writeText(copyMe);
            toast.success('Copied to Clipboard')
        } catch (err) {
            toast.error('Failed to copy!');
        }
    };

    const columns = [
        {
            Header: 'Business Name',
            accessor: 'businessName',
        },
        {
            Header: 'Gas Payment Tnx Count',
            accessor: 'gasPaymentTransactionsCount',
        },
        {
            Header: 'Wallet Address',
            accessor: 'address',
            Cell: (cell: any) => {
                return (
                    <div className="d-flex align-items-center">
                        {cell.value && cell.value.length > 15 ? cell.value.substring(0, 20) + "****" : cell.value}
                        <span className="material-icons-outlined ml-3 cursor-pointer" onClick={() => copyToClipBoard(cell.row.original.addressExplorerUrl)}>copy_all</span>
                    </div>
                )
            }
        }
    ];

    return (
        <>
            <HelmetTitle title="Remittance Wallets" />
            <Layout>
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="row filter-row shadow-none d-flex justify-content-between">
                            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 pb-3 pb-md-0">
                                {loading
                                    ? <Skeleton height={45} />
                                    : <select className="input-select" defaultValue={'DEFAULT'} onChange={(e) => setMerchantId(e.target.value)}>
                                        <option value="DEFAULT" disabled>Select merchant</option>
                                        {merchants && merchants.map((merchant: any, i: number) => <option key={i} value={merchant.id}>{merchant.businessName}</option>)}
                                    </select>
                                }
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 pb-3 pb-md-0">
                                <button className="btn btn-success btn-search rounded-pill" type="button" onClick={handleSearch}>
                                    <i className="fas fa-filter mr-2" />Filter
                                </button>
                            </div>
                        </div>
                        {walletsCases.length === 0
                            ? <LargeCard />
                            : fetching
                                ? <Spinner text="Fetching data..." />
                                : <RemittanceWalletsTable
                                    columns={columns}
                                    data={walletsCases}
                                    merchantId={merchantId}
                                    setFetching={setFetching}
                                />
                        }
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default RemittanceWallets;