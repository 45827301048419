import { toast } from "react-toastify";
import { createBrowserHistory } from "history";

import baseuri from "../../utils/baseuri";
import { saveToken, deleteToken } from "../../utils/utility";

export const login = async (data: any, setLoading: Function) => {
  try {
    setLoading(true);
    const res = await baseuri.post("/api/v1/authLock/login", data);
    saveToken(res.data.data);
    createBrowserHistory().push("/");
    createBrowserHistory().go(0);
  } catch (err: any) {
    toast.error('Administrator login failed.');
    setLoading(false);
    throw err;
  }
};

export const logout = () => {
  deleteToken();
  createBrowserHistory().push('/login');
  createBrowserHistory().go(0);
}