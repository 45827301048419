import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from 'react-loading-skeleton';
import moment from "moment";
// import { toast } from "react-toastify";
import DatePicker from "react-datepicker";

import Layout from "./Layout";
import Spinner from "./Spinner";
import HelmetTitle from "./HelmetTitle";
import GeneratedAddressesTable from "./Tables/GeneratedAddressesTable";
import AddressSumaryTable from "./Tables/AddressSumaryTable";
import { getGeneratedAddresses, getAddressSumary } from "../redux/actions/walletAction";
import { getActiveMerchants } from "../redux/actions/merchantAction";

function GeneratedAddresses() {
    const dispatch = useDispatch();

    const [fetching, setFetching] = useState(false);
    const [loading, setLoading] = useState(false);
    const [merchantId, setMerchantId] = useState("");
    const [startDateTimeStamp, setStartDateTimeStamp] = useState(null);
    const [endDateTimeStamp, setEndDateTimeStamp] = useState(null);

    const { merchants } = useSelector((store: any) => store.merchant);
    const { records, addressCount, totalDeposits, depositMetrics } = useSelector((store: any) => store.wallet);

    useEffect(() => {
        dispatch(getActiveMerchants(setLoading));
        dispatch(getAddressSumary(moment(startDateTimeStamp).valueOf() || "", moment(endDateTimeStamp).valueOf() || "", merchantId, setFetching));
        dispatch(getGeneratedAddresses(moment(startDateTimeStamp).valueOf() || "", moment(endDateTimeStamp).valueOf() || "", merchantId, 1, 50, setFetching));

        /* eslint-disable-next-line */
    }, [dispatch]);

    const handleSearch = () => {
        // if (!(merchantId && startDateTimeStamp && endDateTimeStamp)) return toast.error("Select the input below");

        dispatch(getAddressSumary(moment(startDateTimeStamp).valueOf() || "", moment(endDateTimeStamp).valueOf() || "", merchantId, setFetching));
        dispatch(getGeneratedAddresses(moment(startDateTimeStamp).valueOf() || "", moment(endDateTimeStamp).valueOf() || "", merchantId, 1, 50, setFetching));
    }

    const columns = [
        {
            Header: 'Merchant Name',
            accessor: 'merchantName',
        },
        {
            Header: 'Gas Balance',
            accessor: 'gasBalance',
        },
        {
            Header: 'Deposit Transaction Count',
            accessor: 'depositTransactionCount',
        },
        {
            Header: 'Completed Remittance Transactions',
            accessor: 'completedRemittanceTransactions',
        },
        {
            Header: 'Last Gas Payment TimeStamp',
            accessor: 'lastGasPaymentTimeStamp',
        },
        {
            Header: 'Last Remittance TimeStamp',
            accessor: 'lastRemittanceTimeStamp',
        },
        {
            Header: 'Address Generation TimeStamp',
            accessor: 'addressgenerationTimeStamp',
            Cell: (cell: any) => moment(cell.value).format("lll")
        }
    ];

    const queues = [
        {
            Header: 'Currency',
            accessor: 'currency',
        },
        {
            Header: 'Value',
            accessor: 'value',
        },
        {
            Header: 'Count',
            accessor: 'count',
        }
    ];

    return (
        <>
            <HelmetTitle title="Remittance Wallets" />
            <Layout>
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="row filter-row shadow-none">
                            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 pb-3 pb-md-0">
                                {loading
                                    ? <Skeleton height={45} />
                                    : <select className="input-select" defaultValue={'DEFAULT'} onChange={(e) => setMerchantId(e.target.value)}>
                                        <option value="DEFAULT" disabled>Select merchant</option>
                                        {merchants.map((merchant: any, i: number) => <option key={i} value={merchant.id}>{merchant.businessName}</option>)}
                                    </select>
                                }
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 pb-3 pb-md-0">
                                <DatePicker
                                    className="input-select"
                                    placeholderText="Start Date"
                                    selected={startDateTimeStamp}
                                    onChange={(date: any) => setStartDateTimeStamp(date)}
                                />
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 pb-3 pb-md-0">
                                <DatePicker
                                    className="input-select"
                                    placeholderText="End Date"
                                    selected={endDateTimeStamp}
                                    onChange={(date: any) => setEndDateTimeStamp(date)}
                                />
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 ml-auto">
                                <button type="button" className="btn btn-success btn-search rounded-pill" disabled={fetching ? true : false} onClick={handleSearch}>
                                    <i className="fas fa-filter mr-2" />Filter
                                </button>
                            </div>
                        </div>
                        <div className="row filter-row shadow-none d-flex align-items-center d-flex justify-content-between px-3">
                            <div className="text-center">
                                {loading
                                    ? <Skeleton height={45} />
                                    : <>
                                        <p className="mb-0">Total Deposits</p>
                                        <h3 className="mb-0">{totalDeposits}</h3>
                                    </>
                                }
                            </div>
                            <div className="text-center">
                                {loading
                                    ? <Skeleton height={45} />
                                    : <>
                                        <p className="mb-0">Address Count</p>
                                        <h3 className="mb-0">{addressCount}</h3>
                                    </>
                                }
                            </div>
                        </div>
                        {fetching
                            ? <Spinner text="Fetching data..." />
                            : <AddressSumaryTable
                                columns={queues}
                                data={depositMetrics}
                                startDateTimeStamp={startDateTimeStamp}
                                endDateTimeStamp={endDateTimeStamp}
                                merchantId={merchantId}
                                setFetching={setFetching}
                            />
                        }
                        {fetching
                            ? <Spinner text="Fetching data..." />
                            : <GeneratedAddressesTable
                                columns={columns}
                                data={records}
                                startDateTimeStamp={startDateTimeStamp}
                                endDateTimeStamp={endDateTimeStamp}
                                merchantId={merchantId}
                                setFetching={setFetching}
                            />
                        }
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default GeneratedAddresses;