import actions from "./action";
import baseuri from "../../utils/baseuri";
import { retrieveToken } from "../../utils/utility";

export const getActiveMerchants = (setLoading: Function) => {
    return async (dispatch: Function) => {
        try {
            setLoading(true);
            const res = await baseuri.get("/api/v1/merchant/activemerchants", {
                headers: {
                    'Authorization': `Bearer ${retrieveToken().accessToken}`
                }
            })
            dispatch({
                type: actions.ACTIVE_MERCHANTS,
                payload: res.data.data
            })
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    }
}