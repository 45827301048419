import actions from "../actions/action";

const initialState = {
  merchants: []
};

interface Action {
  type: string;
  payload: any;
};

const merchantReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case actions.ACTIVE_MERCHANTS:
      return { ...state, merchants: action.payload };

    default: return state;
  }
};

export default merchantReducer;