import React from 'react';

type Props = {
  current: number
  total: number
  changePage: Function
}

function TablePagination(props: Props) {

  function pageCircle(e: any) {
    let tt: any = e.target;
    props.changePage(Number(tt.innerText))
  }

  const _arranger = (total: number, current: number) => {
    let page_number_list: Array<number> = [];
    for (let i = 2; i < total; i++) { page_number_list.push(i); }

    const position_of_current = page_number_list.indexOf(current);

    const _before_current = () => {
      if (position_of_current > 2) {
        return (
          <div className="d-flex align-items-center">
            <i className="fas fa-ellipsis-h"></i>
            {<div className="page-number" onClick={pageCircle}>{page_number_list[position_of_current - 2]}</div>}
            {<div className="page-number" onClick={pageCircle}>{page_number_list[position_of_current - 1]}</div>}
          </div>
        )
      } else {
        return (
          <div className="d-flex align-items-center">
            {(position_of_current - 2) >= 0 && <div className="page-number" onClick={pageCircle}>{page_number_list[position_of_current - 2]}</div>}
            {(position_of_current - 1) >= 0 && <div className="page-number" onClick={pageCircle}>{page_number_list[position_of_current - 1]}</div>}
          </div>
        )
      }
    }

    const _after_current = () => {

      if (page_number_list[position_of_current + 2] < total - 1) {
        return (
          <div className="d-flex align-items-center">
            <div className="page-number" onClick={pageCircle}>{page_number_list[position_of_current + 1]}</div>
            <div className="page-number" onClick={pageCircle}>{page_number_list[position_of_current + 2]}</div>
            <i className="fas fa-ellipsis-h"></i>
          </div>
        )
      } else {

        return (
          <div className="d-flex align-items-center">
            {page_number_list[position_of_current + 1] && <div className="page-number" onClick={pageCircle}>{page_number_list[position_of_current + 1]}</div>}
            {page_number_list[position_of_current + 2] && <div className="page-number" onClick={pageCircle}>{page_number_list[position_of_current + 2]}</div>}
          </div>
        )
      }
    }

    if (current !== 1 && current !== total) {
      return (
        <div className="d-flex align-items-center">
          {_before_current()}
          <div className={`page-number current`}>{current}</div>
          {_after_current()}
        </div>
      )
    }

  }

  const _nextFour = () => {
    if (props.current === 1) {
      let page_number_list: Array<number> = [];
      for (let i = 2; i < props.total; i++) { page_number_list.push(i); }

      if (props.total > 4) {
        return (
          <div className="d-flex align-items-center">
            <div className="page-number" onClick={pageCircle}>2</div>
            <div className="page-number" onClick={pageCircle}>3</div>
            <i className="fas fa-ellipsis-h"></i>
          </div>
        )
      } else {
        return (
          <div className="d-flex align-items-center">
            {page_number_list.map(page => <div key={page} className="page-number" onClick={pageCircle}>{page}</div>)}
          </div>
        )
      }
    }
  }

  const _fourBehind = () => {
    if (props.current === props.total) {
      let page_number_list: Array<number> = [];
      for (let i = 2; i < props.total; i++) { page_number_list.push(i); }
      let lastInArray = page_number_list.length - 1;

      if (page_number_list.length > 4) {
        return (
          <div className="d-flex align-items-center">
            <i className="fas fa-ellipsis-h"></i>
            <div className="page-number" onClick={pageCircle}>{page_number_list[lastInArray - 1]}</div>
            <div className="page-number" onClick={pageCircle}>{page_number_list[lastInArray]}</div>
          </div>
        )
      } else {
        return (
          <div className="d-flex align-items-center">
            {page_number_list.map(page => <div key={page} className="page-number" onClick={pageCircle}>{page}</div>)}
          </div>
        )
      }
    }
  }

  const _lastPage = (current: number) => {
    if (props.total > 1) {
      return <div onClick={pageCircle} className={`page-number ${current === props.total && 'current'}`}>{props.total}</div>
    }
  }

  const nextPage = () => {
    if (props.current < props.total) {
      props.changePage(props.current + 1)
    }
  }

  const prevPage = () => {
    if (props.current > 1) {
      props.changePage(props.current - 1)
    }
  }

  return (
    <div className="d-flex align-items-center">
      <div className="font14 semi-bold cursor-pointer" onClick={prevPage}>Prev</div>
      <div className="w2"></div>
      <div className="d-flex align-items-center">
        <div className={`page-number ${props.current === 1 && 'current'}`} onClick={pageCircle}>1</div>
        {_nextFour()}
        {_arranger(props.total, props.current)}
        {_fourBehind()}
        {_lastPage(props.current)}
      </div>
      <div className="w2"></div>
      <div className="font14 semi-bold cursor-pointer" onClick={nextPage}>Next</div>
    </div>
  )
}

export default TablePagination;