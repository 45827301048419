import actions from "../actions/action";

let initialState = {
    currencies: []
}

type Actions = {
    type: string,
    payload: any;
}

const generalReducer = (state = initialState, action: Actions) => {
    switch (action.type) {
        case actions.CURRENCY:
            return { ...state, currencies: action.payload };

        default: return state;
    }
}

export default generalReducer;