import { combineReducers } from "redux";
import generalReducer from "./generalReducer";
import reportReducer from "./reportReducer";
import merchantReducer from "./merchantReducer";
import walletReducer from "./walletReducer";
import remittanceReducer from "./remittanceReducer";
import transactionsReducer from "./transactionsReducer";

export default combineReducers({
  general: generalReducer,
  report: reportReducer,
  merchant: merchantReducer,
  wallet: walletReducer,
  remittance: remittanceReducer,
  transactions: transactionsReducer
});