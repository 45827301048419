import actions from "./action";
import baseuri from "../../utils/baseuri";
import { retrieveToken } from "../../utils/utility";

export const getRemittanceWallets = (merchantId: string, setFetching: Function) => {
    return async (dispatch: Function) => {
        try {
            setFetching(true);
            const res = await baseuri.get(`/api/v1/walletReport/remittanceWallets?merchantId=${merchantId}`, {
                headers: {
                    'Authorization': `Bearer ${retrieveToken().accessToken}`
                }
            })
            dispatch({
                type: actions.REMITTANCE_WALLETS,
                payload: res.data.data
            })
            setFetching(false);
        } catch (e) {
            console.error(e)
            setFetching(false);
        }
    }
}

export const getGeneratedAddresses = (startDateTimeStamp: any, endDateTimeStamp: any, merchantId: string, page: number, pageSize: number, setFetching: Function) => {
    return async (dispatch: Function) => {
        try {
            setFetching(true);
            const res = await baseuri.get(`/api/v1/walletReport/generatedAddresses?startDateTimeStamp=${startDateTimeStamp}&endDateTimeStamp=${endDateTimeStamp}&merchantId=${merchantId}&page=${page}&pageSize=${pageSize}`, {
                headers: {
                    'Authorization': `Bearer ${retrieveToken().accessToken}`
                }
            })
            dispatch({
                type: actions.GENERATED_ADDRESSES,
                payload: res.data.data
            });
            setFetching(false);
        } catch (e) {
            console.error(e);
            setFetching(false);
        }
    }
}

export const getAddressSumary = (startDateTimeStamp: any, endDateTimeStamp: any, merchantId: string, setFetching: Function) => {
    return async (dispatch: Function) => {
        try {
            setFetching(true);
            const res = await baseuri.get(`/api/v1/walletReport/addressSumary?startDateTimeStamp=${startDateTimeStamp}&endDateTimeStamp=${endDateTimeStamp}&merchantId=${merchantId}`, {
                headers: {
                    'Authorization': `Bearer ${retrieveToken().accessToken}`
                }
            })
            dispatch({
                type: actions.ADDRESSES_SUMARY,
                payload: res.data.data
            })
            setFetching(false);
        } catch (e) {
            console.error(e)
            setFetching(false);
        }
    }
}