import React from 'react';
import moment from 'moment';
import { createBrowserHistory } from 'history';

export const saveToken = (token: any) => {
	localStorage.setItem("accessToken", JSON.stringify(token))
}

export const retrieveToken = () => {
	const token = localStorage.getItem("accessToken");
	if (!token) return null;
	return JSON.parse(token);
}

export const deleteToken = () => {
	localStorage.removeItem("accessToken");
}

export const Authenticated = function (Route: any) {
	return class Protect extends React.Component {
		render() {
			let { response } = isAuth();
			if (response) {
				createBrowserHistory().push("/");
				createBrowserHistory().go(0);
			} else {
				return <Route {...this.props} />
			}
		}
	}
}

export const Authorize = function (Route: any) {
	return class Protect extends React.Component {
		render() {
			let { data } = isAuth();
			if (data) {
				return <Route {...this.props} />
			} else {
				createBrowserHistory().push('/login');
				createBrowserHistory().go(0);
				return null;
			}
		}
	}
}

export const isAuth = () => {
	if (localStorage.getItem("accessToken") !== null) {
		let t = JSON.parse(localStorage.getItem("accessToken") || "{}")
		if (moment().isBefore(moment(t.expires))) {
			return {
				data: t,
				response: true
			}
		}
	}
	return { data: null, response: false }
}