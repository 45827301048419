import Skeleton from 'react-loading-skeleton';

function SmallCard() {
    return (
        <div className="row">
            {Array(6).fill(undefined).map((item: any, i) => (
                <div key={i} className="col-md-6 col-sm-6 col-lg-6 col-xl-2">
                    <div className="card flex-fill tickets-card">
                        <div className="card-header">
                            <div className="text-center w-100 p-3">
                                <h3 className="bl-text mb-1">
                                    <Skeleton />
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SmallCard;