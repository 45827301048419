import { FC } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";

interface ILayout { }

const Layout: FC<ILayout> = ({ children }) => {
    return (
        <div className="main-wrapper">
            <Header />
            <Sidebar />
            {children}
        </div>
    )
}

export default Layout;