import { useMemo } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTable, usePagination, useGlobalFilter } from 'react-table';

import TableSearch from './TableSearch';
import TablePagination from './TablePagination';
import { getWithdrawals } from '../../redux/actions/transactionsAction';

function TransactionsWithdrawalsTable(props) {
    const dispatch = useDispatch();

    const { destinationAddress, currencySymbol, transactionStatus, startDateTimeStamp, endDateTimeStamp, merchantId, setFetching } = props;

    const { currentPage, pageSize, totalPages } = useSelector((store) => store.transactions);

    let columns = useMemo(() => props.columns, [props.columns])
    let data = useMemo(() => props.data, [props.data])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state: { globalFilter },
        gotoPage,
        preGlobalFilteredRows,
        setGlobalFilter
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize, currentPage }
        },
        useGlobalFilter,
        usePagination

    )

    function changePage(e) {
        gotoPage(dispatch(getWithdrawals(destinationAddress, currencySymbol, transactionStatus, startDateTimeStamp || "", endDateTimeStamp || "", merchantId, currentPage === 1 ? e++ : e--, pageSize, setFetching)));
    }

    return (
        <div>
            <div className="table-wrapper">
                <div className="d-flex justify-content-center">
                    <TableSearch preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                </div>
                <br />
                <table {...getTableProps()}>
                    <thead>
                        {
                            headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        headerGroup.headers.map(column => (
                                            <th style={{ textAlign: "left" }} {...column.getHeaderProps()}>{column.render('Header')}</th>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {
                                            row.cells.map(cell => {
                                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="mt-3 d-flex justify-content-center">
                <TablePagination current={currentPage} total={totalPages} changePage={changePage} />
            </div>
        </div>
    )
}

export default TransactionsWithdrawalsTable;