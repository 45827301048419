import actions from "../actions/action";

const initialState = {
    records: [],
    pageSize: 50,
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0
};

type Action = {
    type: string;
    payload: any;
};

const transactionsReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case actions.DEPOSITS:
            return { ...state, ...action.payload };

        case actions.WITHDRAWALS:
            return { ...state, ...action.payload };

        default: return state;
    }
};

export default transactionsReducer;