const actions = {

  // General Action
  CURRENCY: "CURRENCY",

  // Merchant Action
  ACTIVE_MERCHANTS: "ACTIVE_MERCHANTS",

  // Wallet Action
  REMITTANCE_WALLETS: "REMITTANCE_WALLETS",
  GENERATED_ADDRESSES: "GENERATED_ADDRESSES",
  ADDRESSES_SUMARY: "ADDRESSES_SUMARY",

  // Remittance Action
  REMITTANCE: "REMITTANCE",
  GAS_PAYMENTS: "GAS_PAYMENTS",
  GAS_TRANSACTIONS: "GAS_TRANSACTIONS",
  REMITTANCE_TRANSACTIONS: "REMITTANCE_TRANSACTIONS",
  REMITTANCE_SUMMARY: "REMITTANCE_SUMMARY",
  GAS_PAYMENT_SUMMARY: "GAS_PAYMENT_SUMMARY",

  // Transactions Action
  DEPOSITS: "DEPOSITS",
  WITHDRAWALS: "WITHDRAWALS",

  // Report Action
  TIME_SERIES_REPORT: "TIME_SERIES_REPORT"

};

export default actions;