import actions from "../actions/action";

const initialState = {
    walletsCases: [],

    records: [],
    pageSize: 50,
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,

    addressCount: 0,
    totalDeposits: 0,
    depositMetrics: [],
    walletBalanceMetrics: []
};

interface Action {
    type: string;
    payload: any;
};

const merchantReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case actions.REMITTANCE_WALLETS:
            return { ...state, walletsCases: action.payload };

        case actions.GENERATED_ADDRESSES:
            return { ...state, ...action.payload };

        case actions.ADDRESSES_SUMARY:
            return { ...state, ...action.payload };

        default: return state;
    }
};

export default merchantReducer;