import actions from "../actions/action";

const initialState = {
    records: [],
    pageSize: 50,
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,

    currency: [],
    totalNumberOfTransaction: 0,
    totalAmount: 0.0,
    totalNetworkFees: 0.0,
    totalNumberOfNetworkFees: 0.0,

    currencies: [],
    totalAMountSentAsGas: 0.0,
    totalNetworkTransactionFees: 0.0,
    totalUniqueWalletAddresses: null
};

type Action = {
    type: string;
    payload: any;
};

const remittanceReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case actions.REMITTANCE:
            return { ...state, ...action.payload };

        case actions.GAS_PAYMENTS:
            return { ...state, ...action.payload };

        case actions.GAS_TRANSACTIONS:
            return { ...state, ...action.payload };

        case actions.REMITTANCE_TRANSACTIONS:
            return { ...state, ...action.payload };

        case actions.REMITTANCE_SUMMARY:
            return { ...state, ...action.payload };

        case actions.GAS_PAYMENT_SUMMARY:
            return { ...state, ...action.payload };

        default: return state;
    }
};

export default remittanceReducer;