import actions from "./action";
import baseuri from "../../utils/baseuri";
import { retrieveToken } from "../../utils/utility";

export const getTimeSeriesReport = (startDate: any, endDate: any, setFetching: Function) => {
    return async (dispatch: Function) => {
        try {
            setFetching(true);
            const res = await baseuri.get(`/api/v1/reporting/timeseriesreport?From=${startDate}&To=${endDate}`, {
                headers: {
                    'Authorization': `Bearer ${retrieveToken().accessToken}`
                }
            })
            dispatch({
                type: actions.TIME_SERIES_REPORT,
                payload: res.data.data
            })
            setFetching(false);
        } catch (e) {
            console.error(e)
            setFetching(false);
        }
    }
}