import { Offline, Online } from "react-detect-offline"
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import 'react-loading-skeleton/dist/skeleton.css';

import store from "./redux/store";
import { Authenticated, Authorize } from "./utils/utility";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import GasTransactions from "./components/GasTransactions";
import GasPayment from "./components/GasPayment";
import Remittance from "./components/Remittance";
import RemittanceTransactions from "./components/RemittanceTransactions";
import RemittanceWallets from "./components/RemittanceWallets";
import GeneratedAddresses from "./components/GeneratedAddresses";
import TransactionsDeposite from "./components/TransactionsDeposite";
import TransactionsWithdrawals from "./components/TransactionsWithdrawals";
import OfflineStatus from "./components/OfflineStatus";

function App() {
  return (
    <>
      <Online>
        <Provider store={store} >
          <Router>
            <Switch>
              <Route exact path="/login" component={Authenticated(Login)} />
              <Route exact path="/" component={Authorize(Dashboard)} />
              <Route exact path="/gas-transactions" component={Authorize(GasTransactions)} />
              <Route exact path="/gas-payment" component={Authorize(GasPayment)} />
              <Route exact path="/remittance" component={Authorize(Remittance)} />
              <Route exact path="/remittance-transactions" component={Authorize(RemittanceTransactions)} />
              <Route exact path="/remittance-wallets" component={Authorize(RemittanceWallets)} />
              <Route exact path="/generated-addresses" component={Authorize(GeneratedAddresses)} />
              <Route exact path="/transactions-deposite" component={Authorize(TransactionsDeposite)} />
              <Route exact path="/transactions-withdrawals" component={Authorize(TransactionsWithdrawals)} />
              <Route path="*" component={Authenticated(Login)} />
            </Switch>
            <ToastContainer />
          </Router>
        </Provider>
      </Online>
      <Offline><OfflineStatus /></Offline>
    </>
  );
}

export default App;