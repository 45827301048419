import React from "react";

import loading from "../assets/img/loading.gif";

interface Props {
  text: string;
};

function Loading(props: Props) {
  return (
    <div className="loader">
      <img src={loading} alt="" />
      <h4 className="mb-0 mt-2">{props.text}</h4>
    </div>
  )
}

export default Loading;