import { FC } from "react";
import Layout from "./Layout";

interface Props {
    text: string;
};

const Spinner: FC<Props> = (props) => {
    return (
        <Layout>
            <div className="d-flex align-items-center justify-content-center">
                <h4 className="mb-0">{props.text}</h4>
            </div>
        </Layout>
    )
}

export default Spinner;