import actions from "./action";
import baseuri from "../../utils/baseuri";
import { retrieveToken } from "../../utils/utility";

export const getRemittance = (merchantId: string, currencyAddressPoolId: string, startTimeStamp: any, endTimeStamp: any, page: number, pageSize: number, setFetching: Function) => {
    return async (dispatch: Function) => {
        try {
            setFetching(true);
            const res = await baseuri.get(`/api/v1/remittanceReport/remittance?MerchantId=${merchantId}&CurrencyAddressPoolId=${currencyAddressPoolId}&StartTimeStamp=${startTimeStamp}&EndTimeStamp=${endTimeStamp}&Page=${page}&PageSize=${pageSize}`, {
                headers: {
                    'Authorization': `Bearer ${retrieveToken().accessToken}`
                }
            })
            dispatch({
                type: actions.REMITTANCE,
                payload: res.data.data
            })
            setFetching(false);
        } catch (e) {
            console.error(e);
            setFetching(false);
        }
    }
}

export const getRemittanceSummary = (managedWalletAddress: string, transactionStatus: string, currencySymbol: string, startTimeStamp: any, endTimeStamp: any, merchantId: string, setFetching: Function) => {
    return async (dispatch: Function) => {
        try {
            setFetching(true);
            const res = await baseuri.get(`/api/v1/remittanceReport/remittance_summary?managedWalletAddress=${managedWalletAddress}&transactionStatus=${transactionStatus}&currencySymbol=${currencySymbol}&startDateTimeStamp=${startTimeStamp}&endDateTimeStamp=${endTimeStamp}&merchantId=${merchantId}`, {
                headers: {
                    'Authorization': `Bearer ${retrieveToken().accessToken}`
                }
            })
            dispatch({
                type: actions.REMITTANCE_SUMMARY,
                payload: res.data.data
            })
            setFetching(false);
        } catch (e) {
            console.error(e);
            setFetching(false);
        }
    }
}

export const getGasPayments = (merchantId: string, currencyAddressPoolId: string, startTimeStamp: any, endTimeStamp: any, page: number, pageSize: number, setFetching: Function) => {
    return async (dispatch: Function) => {
        try {
            setFetching(true);
            const res = await baseuri.get(`/api/v1/remittanceReport/gasPayments?MerchantId=${merchantId}&CurrencyAddressPoolId=${currencyAddressPoolId}&StartTimeStamp=${startTimeStamp}&EndTimeStamp=${endTimeStamp}&Page=${page}&PageSize=${pageSize}`, {
                headers: {
                    'Authorization': `Bearer ${retrieveToken().accessToken}`
                }
            })
            dispatch({
                type: actions.GAS_PAYMENTS,
                payload: res.data.data
            })
            setFetching(false);
        } catch (e) {
            console.error(e);
            setFetching(false);
        }
    }
}

export const getGasPaymentSummary = (managedWalletAddress: string, transactionStatus: string, currencySymbol: string, startTimeStamp: any, endTimeStamp: any, merchantId: string, setFetching: Function) => {
    return async (dispatch: Function) => {
        try {
            setFetching(true);
            const res = await baseuri.get(`/api/v1/remittanceReport/gas_payment_summary?managedWalletAddress=${managedWalletAddress}&transactionStatus=${transactionStatus}&currencySymbol=${currencySymbol}&startDateTimeStamp=${startTimeStamp}&endDateTimeStamp=${endTimeStamp}&merchantId=${merchantId}`, {
                headers: {
                    'Authorization': `Bearer ${retrieveToken().accessToken}`
                }
            })
            dispatch({
                type: actions.GAS_PAYMENT_SUMMARY,
                payload: res.data.data
            })
            setFetching(false);
        } catch (e) {
            console.error(e);
            setFetching(false);
        }
    }
}

export const getGasTransactions = (managedWalletAddress: string, transactionStatus: string, currencySymbol: string, startDateTimeStamp: any, endDateTimeStamp: any, merchantId: string, page: number, pageSize: number, setFetching: Function) => {
    return async (dispatch: Function) => {
        try {
            setFetching(true);
            const res = await baseuri.get(`/api/v1/remittanceReport/gasTransactions?managedWalletAddress=${managedWalletAddress}&transactionStatus=${transactionStatus}&currencySymbol=${currencySymbol}&startDateTimeStamp=${startDateTimeStamp}&endDateTimeStamp=${endDateTimeStamp}&merchantId=${merchantId}&page=${page}&pageSize=${pageSize}`, {
                headers: {
                    'Authorization': `Bearer ${retrieveToken().accessToken}`
                }
            })
            dispatch({
                type: actions.GAS_TRANSACTIONS,
                payload: res.data.data
            })
            setFetching(false);
        } catch (e) {
            console.error(e);
            setFetching(false);
        }
    }
}

export const getRemittanceTransactions = (managedWalletAddress: string, remittanceReceivableAddress: string, transactionStatus: string, currencySymbol: string, startDateTimeStamp: any, endDateTimeStamp: any, page: number, pageSize: number, setFetching: Function) => {
    return async (dispatch: Function) => {
        try {
            setFetching(true);
            const res = await baseuri.get(`/api/v1/remittanceReport/remittanceTransactions?managedWalletAddress=${managedWalletAddress}&remittanceReceivableAddress=${remittanceReceivableAddress}&transactionStatus=${transactionStatus}&currencySymbol=${currencySymbol}&startDateTimeStamp=${startDateTimeStamp}&endDateTimeStamp=${endDateTimeStamp}&page=${page}&pageSize=${pageSize}`, {
                headers: {
                    'Authorization': `Bearer ${retrieveToken().accessToken}`
                }
            })
            dispatch({
                type: actions.REMITTANCE_TRANSACTIONS,
                payload: res.data.data
            })
            setFetching(false);
        } catch (e) {
            console.error(e);
            setFetching(false);
        }
    }
}