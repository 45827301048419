import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";

import Layout from "./Layout";
import HelmetTitle from "./HelmetTitle";
import SmallCard from "./Cards/SmallCard";
import Spinner from "./Spinner";
import VolumnChat from "./Charts/VolumnChat";
import { getCurrency } from "../redux/actions/generalAction";
import { getTimeSeriesReport } from "../redux/actions/reportAction";

function Dashboard() {
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);

  const { BUSD } = useSelector((store: any) => store.report);
  const { currencies } = useSelector((store: any) => store.general);

  const volumnCount = BUSD.map((item: any) => item.count);
  const volumnDate = BUSD.map((item: any) => moment(item.date).format("ll"));

  useEffect(() => {
    dispatch(getCurrency(setLoading));
    dispatch(getTimeSeriesReport(moment(startDate).valueOf() || "", moment(endDate).valueOf() || "", setFetching));

    /* eslint-disable-next-line */
  }, [dispatch]);

  const handleSearch = () => {
    dispatch(getTimeSeriesReport(moment(startDate).valueOf() || "", moment(endDate).valueOf() || "", setFetching))
  }

  return (
    <>
      <HelmetTitle title="Dashboard" />
      <Layout>
        <div className="page-wrapper">
          <div className="content container-fluid pb-0">
            {loading
              ? <SmallCard />
              : <div className="row">
                {currencies.map((currency: any) => (
                  <div key={currency.id} className="col-md-6 col-sm-6 col-lg-6 col-xl-2">
                    <div className="card flex-fill tickets-card">
                      <div className="card-header">
                        <div className="text-center w-100 p-3">
                          <h3 className="bl-text mb-1">{currency.symbol}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            }
            <div className="row filter-row">
              <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 pb-3 pb-md-0">
                <DatePicker
                  className="input-select"
                  placeholderText="Start Date"
                  selected={startDate}
                  onChange={(date: any) => setStartDate(date)}
                />
              </div>
              <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 pb-3 pb-md-0">
                <DatePicker
                  className="input-select"
                  placeholderText="End Date"
                  selected={endDate}
                  onChange={(date: any) => setEndDate(date)}
                />
              </div>
              <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 ml-auto">
                <button type="button" className="btn btn-success btn-search rounded-pill" disabled={fetching ? true : false} onClick={handleSearch}>
                  <i className="fas fa-filter mr-2" />Filter
                </button>
              </div>
            </div>
            {fetching
              ? <Spinner text="Fetching data..." />
              : <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <VolumnChat labels={volumnDate} data={volumnCount} />
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Dashboard;