import ReactDOM from "react-dom";

import "./assets/css/bootstrap.min.css";
import "./assets/plugins/fontawesome/css/fontawesome.min.css";
import "./assets/plugins/fontawesome/css/all.min.css";
import "./assets/css/material.css";
import "./assets/css/line-awesome.min.css";
import "./assets/css/select2.min.css";
import "./assets/css/style.css";
import 'tippy.js/dist/tippy.css';
import App from "./App";

ReactDOM.render(<App />, document.getElementById("root"));