import { useState, useRef } from 'react';
import { useAsyncDebounce } from 'react-table';

function TableSearch({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const inputRef = useRef(null);
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200);

  return (
    <div className="search-bar" onClick={() => { inputRef.current.select() }}>
      <input
        ref={inputRef}
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
      <div className="d-flex justify-content-center">
        <span className="material-icons-outlined">search</span>
      </div>
    </div>
  )
}

export default TableSearch;