import React from "react";
import { Helmet } from "react-helmet";

interface Props {
  title: string;
}

function HelmetTitle(props: Props) {
  return (
    <Helmet>
      <title>Switch Wallet - {props.title}</title>
    </Helmet>
  );
};

export default HelmetTitle;