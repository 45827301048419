import { FC, useState } from "react";
import { Link } from "react-router-dom";

import logo from "../assets/img/logo.png";
import avatar from "../assets/img/user.jpg";
import Loading from "./Loading";
import { logout } from "../redux/actions/authAction";

const Header: FC = () => {
    const [loading, setLoading] = useState(false);

    const handleLogout = () => {
        setLoading(true);
        setTimeout(() => { logout() }, 1000);
    }

    return (
        <>
            {loading
                ? <Loading text="Logging out..." />
                : <div className="header d-flex">
                    <div className="header-left">
                        <Link to="/" className="logo">
                            <img src={logo} width={40} height={40} alt="" />
                        </Link>
                    </div>
                    <div className="header-center">
                        <h1>Switch Wallet</h1>
                    </div>
                    <Link id="mobile_btn" className="mobile_btn" to="#">
                        <i className="fa fa-bars" />
                    </Link>
                    <ul className="nav user-menu ml-auto">
                        <li className="nav-item dropdown has-arrow main-drop">
                            <Link to="#" className="dropdown-toggle nav-link" data-toggle="dropdown">
                                <span className="user-img"><img src={avatar} alt="" /></span>
                                <span>Admin</span>
                            </Link>
                            <div className="dropdown-menu">
                                <Link className="dropdown-item" to="#" onClick={handleLogout}>Logout</Link>
                            </div>
                        </li>
                    </ul>
                    <div className="dropdown mobile-user-menu">
                        <Link to="#" className="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v" /></Link>
                        <div className="dropdown-menu dropdown-menu-right">
                            <Link className="dropdown-item" to="#" onClick={handleLogout}>Logout</Link>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Header;