import React from "react";

function LargeCard() {
    return (
        <div className="text-center bg-white py-5 rounded-5">
            <img src="assets/img/empty.png" alt="" />
            <h1 className="pt-4">No data available</h1>
            <p>No data is available for the selected timeframe.</p>
        </div>
    );
};

export default LargeCard;