import { useState } from "react";
import Tippy from '@tippyjs/react';
import { Link, useLocation } from "react-router-dom";

import Loading from "./Loading";
import { logout } from "../redux/actions/authAction";

function Sidebar() {
    const { pathname } = useLocation();

    const [loading, setLoading] = useState(false);

    const handleLogout = () => {
        setLoading(true);
        setTimeout(() => { logout() }, 1000);
    }

    const handleRoute = () => {
        switch (pathname) {
            case '/': {
                return 'Dashboard';
            }
            case '/gas-transactions': {
                return 'Gas Transactions';
            }
            case '/gas-payment': {
                return 'Gas Payment';
            }
            case '/remittance': {
                return 'Remittance';
            }
            case '/remittance-transactions': {
                return 'Remittance Transactions';
            }
            case '/remittance-wallets': {
                return 'Remittance Wallets';
            }
            case '/generated-addresses': {
                return 'Generated Addresses';
            }
            case '/transactions-deposite': {
                return 'Transactions Deposite';
            }
            case '/transactions-withdrawals': {
                return 'Transactions Withdrawals';
            }
            default: return null;
        }
    };

    return (
        <>
            {loading
                ? <Loading text="Logging out..." />
                : <div className="sidebar">
                    <div className="sidebar-left">
                        <div className="nav flex-column nav-pills">
                            <Link className={`nav-link ${pathname === '/' ? "active" : ''}`} to="/">
                                <span className="material-icons-outlined">dashboard</span>
                            </Link>
                            <Link className={`nav-link ${pathname.includes('/gas-transactions') ? "active" : ''}`} to="/gas-transactions">
                                <span className="material-icons-outlined">web_asset</span>
                            </Link>
                            <Link className={`nav-link ${pathname.includes('/gas-payment') ? "active" : ''}`} to="/gas-payment">
                                <span className="material-icons-outlined">indeterminate_check_box</span>
                            </Link>
                            <Link className={`nav-link ${pathname.includes('/remittance') ? "active" : ''}`} to="/remittance">
                                <span className="material-icons-outlined">track_changes</span>
                            </Link>
                            <Link className={`nav-link ${pathname.includes('/remittance-transactions') ? "active" : ''}`} to="/remittance-transactions">
                                <span className="material-icons-outlined">shutter_speed</span>
                            </Link>
                            <Link className={`nav-link ${pathname.includes('/remittance-wallets') ? "active" : ''}`} to="/remittance-wallets">
                                <span className="material-icons-outlined">leaderboard</span>
                            </Link>
                            <Link className={`nav-link ${pathname.includes('/generated-addresses') ? "active" : ''}`} to="/generated-addresses">
                                <span className="material-icons-outlined">topic</span>
                            </Link>
                            <Link className={`nav-link ${pathname.includes('/transactions-deposite') ? "active" : ''}`} to="/transactions-deposite">
                                <span className="material-icons-outlined">sync_alt</span>
                            </Link>
                            <Link className={`nav-link ${pathname.includes('/transactions-withdrawals') ? "active" : ''}`} to="/transactions-withdrawals">
                                <span className="material-icons-outlined">library_add_check</span>
                            </Link>
                            <Link className="nav-link logout" to="#" onClick={handleLogout}>
                                <Tippy content="Logout">
                                    <span className="material-icons-outlined">logout</span>
                                </Tippy>
                            </Link>
                        </div>
                    </div>
                    <div className="sidebar-right">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active">
                                <p>{handleRoute()}</p>
                                <ul>
                                    <li><Link to="/" className="active">{handleRoute()}</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Sidebar;