import { useEffect, useState } from "react";
import Skeleton from 'react-loading-skeleton';
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import Layout from "./Layout";
import Spinner from "./Spinner";
import HelmetTitle from "./HelmetTitle";
import GasPaymentTable from "./Tables/GasPaymentTable";
import GasPaymentSumaryTable from "./Tables/GasPaymentSumaryTable";
import { getActiveMerchants } from "../redux/actions/merchantAction";
import { getGasPayments, getGasPaymentSummary } from "../redux/actions/remittanceAction";

function GasPayment() {
    const dispatch = useDispatch();

    const [merchantId, setMerchantId] = useState("");

    const [managedWalletAddress, setManagedWalletAddress] = useState("");
    const [transactionStatus, setTransactionStatus] = useState("");
    const [currencySymbol, setCurrencySymbol] = useState("");
    const [startTimeStamp, setStartTimeStamp] = useState(null);
    const [endTimeStamp, setEndTimeStamp] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(false);

    const { merchants } = useSelector((store: any) => store.merchant);
    const { records, currencies, totalAMountSentAsGas, totalNetworkTransactionFees } = useSelector((store: any) => store.remittance);

    const statues = [
        { name: "NOT_REFLECTED" },
        { name: "FAILED" },
        { name: "CONFIRMED" },
        { name: "PENDING" },
        { name: "NOT_PROPAGATED" }
    ];

    const coins = [
        { symbol: "BTC" },
        { symbol: "LTC" },
        { symbol: "BNB" },
        { symbol: "USDT" },
        { symbol: "DAI" },
        { symbol: "BUSD" },
        { symbol: "XEND" }
    ];

    useEffect(() => {
        dispatch(getActiveMerchants(setLoading));
        dispatch(getGasPaymentSummary(managedWalletAddress, transactionStatus, currencySymbol, moment(startTimeStamp).valueOf() || "", moment(endTimeStamp).valueOf() || "", merchantId, setFetching));
        dispatch(getGasPayments(merchantId, "", moment(startTimeStamp).valueOf() || "", moment(endTimeStamp).valueOf() || "", 1, 30, setFetching));

        /* eslint-disable-next-line */
    }, [dispatch]);

    const handleSearch = () => {
        // if (!(merchantId && startTimeStamp && endTimeStamp)) {
        //     return toast.error("Select all input below")
        // }

        dispatch(getGasPaymentSummary(managedWalletAddress, transactionStatus, currencySymbol, moment(startTimeStamp).valueOf() || "", moment(endTimeStamp).valueOf() || "", merchantId, setFetching));
        dispatch(getGasPayments(merchantId, "", moment(startTimeStamp).valueOf() || "", moment(endTimeStamp).valueOf() || "", 1, 30, setFetching));
    }

    const copyToClipBoard = async (copyMe: string) => {
        try {
            await navigator.clipboard.writeText(copyMe);
            toast.success('Copied to Clipboard')
        } catch (err) {
            toast.error('Failed to copy!');
        }
    };

    const columns = [
        {
            Header: 'Business Name',
            accessor: 'business',
        },
        {
            Header: 'Currency',
            accessor: 'currency',
        },
        {
            Header: 'Amount',
            accessor: 'amount'
        },
        {
            Header: 'Transaction Fee',
            accessor: 'networkTransactionFee'
        },
        {
            Header: 'Transaction Status',
            accessor: "transactionStatus",
        },
        {
            Header: 'Transaction Hash',
            accessor: 'transactionHash',
            Cell: (cell: any) => {
                return (
                    <div className="d-flex align-items-center">
                        {cell.value && cell.value.length > 15 ? cell.value.substring(0, 20) + "****" : cell.value}
                        <span className="material-icons-outlined ml-3 cursor-pointer" onClick={() => copyToClipBoard(cell.row.original.linkToTransaction)}>copy_all</span>
                    </div>
                )
            }
        },
        {
            Header: 'Wallet Address',
            accessor: 'walletAddress',
            Cell: (cell: any) => {
                return (
                    <div className="d-flex align-items-center">
                        {cell.value && cell.value.length > 15 ? cell.value.substring(0, 20) + "****" : cell.value}
                        <span className="material-icons-outlined ml-3 cursor-pointer" onClick={() => copyToClipBoard(cell.row.original.linkToAddress)}>copy_all</span>
                    </div>
                )
            }
        },
    ];

    const queues = [
        {
            Header: 'Currency Name',
            accessor: 'name',
        },
        {
            Header: 'Amount',
            accessor: 'amount',
        }
    ];

    return (
        <>
            <HelmetTitle title="Gas Payments" />
            <Layout>
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="row filter-row shadow-none">
                            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 pb-3 pb-md-0">
                                {loading
                                    ? <Skeleton height={45} />
                                    : <input className="input-select" placeholder="Enter Wallet Address" onChange={(e) => setManagedWalletAddress(e.target.value)} />
                                }
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 pb-3 pb-md-0">
                                {loading
                                    ? <Skeleton height={45} />
                                    : <select className="input-select" defaultValue={'DEFAULT'} onChange={(e) => setTransactionStatus(e.target.value)}>
                                        <option value="DEFAULT" disabled>Select status</option>
                                        {statues.map((status: any, i: number) => (
                                            <option key={i} value={status.name}>{status.name}</option>
                                        ))}
                                    </select>
                                }
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 pb-3 pb-md-0">
                                {loading
                                    ? <Skeleton height={45} />
                                    : <select className="input-select" defaultValue={'DEFAULT'} onChange={(e) => setCurrencySymbol(e.target.value)}>
                                        <option value="DEFAULT" disabled>Select currency</option>
                                        {coins.map((coin: any, i: number) => (
                                            <option key={i} value={coin.symbol}>{coin.symbol}</option>
                                        ))}
                                    </select>
                                }
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 pb-3 pb-md-0">
                                {loading
                                    ? <Skeleton height={45} />
                                    : <select className="input-select" defaultValue={'DEFAULT'} onChange={(e) => setMerchantId(e.target.value)}>
                                        <option value="DEFAULT" disabled>Select merchant</option>
                                        {merchants.map((merchant: any, i: number) => <option key={i} value={merchant.id}>{merchant.businessName}</option>)}
                                    </select>
                                }
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 pb-3 pb-md-0">
                                <DatePicker
                                    className="input-select"
                                    placeholderText="Start Date"
                                    selected={startTimeStamp}
                                    onChange={(date: any) => setStartTimeStamp(date)}
                                />
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 pb-3 pb-md-0">
                                <DatePicker
                                    className="input-select"
                                    placeholderText="End Date"
                                    selected={endTimeStamp}
                                    onChange={(date: any) => setEndTimeStamp(date)}
                                />
                            </div>
                            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12 ml-auto pt-3 ">
                                <button type="button" className="btn btn-success btn-search rounded-pill" disabled={fetching ? true : false} onClick={handleSearch}>
                                    <i className="fas fa-filter mr-2" />Filter
                                </button>
                            </div>
                        </div>
                        <div className="row filter-row shadow-none d-flex align-items-center d-flex justify-content-between px-3">
                            <div className="text-center">
                                {loading
                                    ? <Skeleton height={45} />
                                    : <>
                                        <p className="mb-0">Total Amount Sent As Gas</p>
                                        <h3 className="mb-0">{totalAMountSentAsGas}</h3>
                                    </>
                                }
                            </div>
                            <div className="text-center">
                                {loading
                                    ? <Skeleton height={45} />
                                    : <>
                                        <p className="mb-0">Total Network Transaction Fees</p>
                                        <h3 className="mb-0">{totalNetworkTransactionFees}</h3>
                                    </>
                                }
                            </div>
                        </div>
                        {fetching
                            ? <Spinner text="Fetching data..." />
                            : <GasPaymentSumaryTable
                                columns={queues}
                                data={currencies}
                                managedWalletAddress={managedWalletAddress}
                                transactionStatus={transactionStatus}
                                currencySymbol={currencySymbol}
                                startTimeStamp={startTimeStamp}
                                endTimeStamp={endTimeStamp}
                                merchantId={merchantId}
                                setFetching={setFetching}
                            />
                        }
                        {fetching
                            ? <Spinner text="Fetching data..." />
                            : <GasPaymentTable
                                columns={columns}
                                data={records}
                                merchantId={merchantId}
                                startTimeStamp={startTimeStamp}
                                endTimeStamp={endTimeStamp}
                                setFetching={setFetching}
                            />
                        }
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default GasPayment;